import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import Chip from '@material-ui/core/Chip';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import { handleEndpointErrors, formatDateYear, formatTime, formatDateWithTime, getReservationStatus, dateISOWithoutTz, SNACKBAR_TIME } from '../../shared/utilities';

import Table from '../Table';
import CustomDialog from '../CustomDialog';
import LoadingCard from '../LoadingCard';
import DataFilters from '../DataFilters';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
  },
  table: {
    '& > .MuiToolbar-root + div': {
      maxHeight: '45vh',
      minHeight: '300px',
      overflow: 'auto',
    },
    '& .MuiTableCell-head:nth-child(5)': {
      minWidth: '90px',
    },
    '& .MuiTableCell-head:nth-child(6)': {
      minWidth: '115px',
    },
    '& .MuiTableCell-head:nth-child(7)': {
      minWidth: '175px',
    },
  }
}));

const AddReservations = (props) => {
  const { onAddReservationsModalClose, tripsTableData, setTripsTableData } = props;
  const classes = useStyles();
  const [maxCapacityModalOpen, setMaxCapacityModalOpen] = React.useState(false);
  const [reservationsTableData, setReservationsTableData] = React.useState([]);
  const [activeFilters, setActiveFilters] = React.useState({});
  const [hasSelectedReservations, setHasSelectedReservations] = React.useState(false);

  const paginationPage = useStoreState(state => state.trips.reservationsPage);
  const paginationPageSize = useStoreState(state => state.trips.reservationsPageSize);
  const [dataLoading, setDataLoading] = React.useState(false);

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const tripSelected = useStoreState(state => state.trips.currentTrip);
  const reservations = useStoreState(state => state.trips.reservations);
  const getReservations = useStoreActions(actions => actions.trips.getReservations);
  const reservationsLoading = useStoreState(state => state.trips.reservationsLoading);
  const addMultipleReservations = useStoreActions(actions => actions.trips.addMultipleReservations);

  const t = useTranslation();

  const reservationsColumns = [
    {
      title: t('trips.tableReservations.columns.id'), field: 'id',
      render: rowData => <Link component={ RouterLink } to={ `/reservations/${ rowData.id }` }>{ rowData.id }</Link>,
      type: 'numeric', align: 'left', defaultSort: 'asc'
    },
    {
      title: t('trips.tableReservations.columns.name'), field: 'passenger.name',
      render: rowData => <Link component={ RouterLink } to={ `/users/${ rowData.passenger.id }` }>{ rowData.passenger.name }</Link>,
      sorting: false, cellStyle: { minWidth: '150px' }
    },
    { title: t('trips.tableReservations.columns.hasTrip'), field: 'tripId', sorting: false, render: rowData => <Chip
      icon={ rowData.tripId ? <CheckIcon /> : <NotInterestedIcon /> }
      label={ rowData.tripId ? `${ t('trips.tableReservations.columns.tripId') }: ${ rowData.tripId }` : t('trips.tableReservations.columns.noTripAssigned') }
      variant="outlined"
      size="small"
    /> },
    { title: t('trips.tableReservations.columns.status'), field: 'status', lookup: getReservationStatus(t), sorting: false },
    { title: t('trips.tableReservations.columns.client'), field: 'client.name', sorting: false, hidden: true },
    { title: t('trips.tableReservations.columns.date'), field: 'formatDate', sorting: false },
    { title: t('trips.tableReservations.columns.departureTime'), field: 'formatDepartureTime', sorting: false, cellStyle: { minWidth: '135px' } },
    { title: t('trips.tableReservations.columns.arrivalTime'), field: 'formatArrivalTime', sorting: false, cellStyle: { minWidth: '135px' } },
    { title: t('trips.tableReservations.columns.ticketId'), field: 'ticket', sorting: false, cellStyle: { minWidth: '90px' } },
    { title: t('trips.tableReservations.columns.createdAt'), field: 'formatCreatedAt', sorting: false, cellStyle: { minWidth: '180px' } },
  ];

  const onMaxCapacityModalClose = () => {
    setMaxCapacityModalOpen(false);
  };

  const addReservations = (reservations, reservationIds) => {
    onAddReservationsModalClose();

    reservations.length && addMultipleReservations({ reservationIds, reservations, tripId: tripSelected.id }).then(() => {
      const tripsState = store.getState().trips;
      if (!tripsState.loading && !tripsState.error) {
        // console.log('success', tripsState);
        const data = [];
        // eslint-disable-next-line array-callback-return
        reservations.map(reservation => {
          data.push({
            passengerName: reservation.passenger.name,
            passengerId: reservation.passenger.id,
            ticketId: reservation.ticket,
            id: reservation.id,
            client: reservation.client.name,
            reserved: formatDateYear(dateISOWithoutTz(new Date(`${ reservation.createdAt }`)), t),
            status: reservation.reservation.status,
            displayOrder: reservation.displayOrder,
            tripId: tripSelected.id,
            place: reservation.place.description,
            location: `${ reservation.place.latitude },${ reservation.place.longitude }`,
          });
        });
        const newData = [...tripsTableData, ...data].sort((a, b) => a.displayOrder - b.displayOrder);
        setTripsTableData(newData);
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        handleEndpointErrors(tripsState, props, setSnackbar, t);
      }
    });
  };

  const onAddReservationsModalSubmit = hasConfirmed => {
    const reservations = reservationsTableData
                          .filter(reservation => reservation.tableData.checked)
                          .map(reservation => ({
                            ...reservation,
                            status: 'approved',
                            reservation: {
                              ...reservation.reservation,
                              status: 'approved',
                            }
                          }));
    const reservationIds = reservations.map( reservation => reservation.id );
    const totalReservations = reservations.length + tripsTableData.length;

    if ( totalReservations <= tripSelected.route.capacity || hasConfirmed ) {
      addReservations(reservations, reservationIds);
    } else {
      setMaxCapacityModalOpen(true);
    }
  };

  const reservationStatusOptions = Object.keys(getReservationStatus(t)).filter(status => ['pending', 'approved'].includes(status)).map(status => ({ label: getReservationStatus(t)[status], value: status }));

  const onGetReservations = (payload) => {
    setDataLoading(true);
    getReservations(({
      ...payload,
      tripId: tripSelected.id,
      departureDate: tripSelected.departureDate.split('T')[0],
      routeId: tripSelected.route.id,
      routeDirection: tripSelected.route.direction,
      scheduleArrivalTime: tripSelected.schedule.arrivalTime,
      scheduleDepartureTime: tripSelected.schedule.departureTime,
      status: !!payload.status?.length ? payload.status : ['pending']
    })).then(() => {
      const tripsState = store.getState().trips;
      if (!tripsState.reservationsLoading && !tripsState.reservationsError) {
        // console.log(tripsState);
        setDataLoading(false);
        if (hasSelectedReservations) {
          setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.INFO, severity: 'info', message: t('trips.tableReservations.infoSelectedReservations') });
          setHasSelectedReservations(false);
        }
      } else {
        handleEndpointErrors(tripsState, props, setSnackbar, t);
      }
    });
  };

  React.useEffect(() => {
    onGetReservations({ loading: true, page: 1, status: ['pending'] });

    return () => setReservationsTableData([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setReservationsTableData(reservations.map(reservation => {
      reservation.formatDate = formatDateYear(reservation.date, t);
      reservation.formatDepartureTime = formatTime(reservation.schedule.departureTime);
      reservation.formatArrivalTime = formatTime(reservation.schedule.arrivalTime);
      reservation.formatCreatedAt = formatDateWithTime(dateISOWithoutTz(new Date(`${ reservation.createdAt }`)), t);
      return { ...reservation };
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservations]);

  return (
    <React.Fragment>
      <DialogTitle>{ t('trips.tableReservations.modalTitle') }</DialogTitle>
      <DialogContent>
        <DataFilters
          setActiveFilters={ setActiveFilters }
          filters={[
            {
              type: 'text',
              name: t('reservations.searchPlaceholder'),
              property: 'textSearch',
              active: '',
              width: '250px',
              onChangeFn: (filters) => {
                onGetReservations({ page: 1, ...filters });
              }
            },
            {
              type: 'select',
              multiple: true,
              name: t('reservations.status'),
              property: 'status',
              options: reservationStatusOptions,
              active: ['pending'],
              onChangeFn: (filters) => {
                onGetReservations({ page: 1, ...filters });
              }
            },
          ]}
        />
        { reservationsLoading ? <LoadingCard length={ 1 } height={ 564 } /> : <Table
          className={ classes.table }
          title={ t('trips.tableReservations.title') }
          columns={ reservationsColumns }
          data={ reservationsTableData }
          selection
          updateColumns="TripsAddReservations"
          search={ false }
          customPagination={{
            page: paginationPage,
            size: paginationPageSize,
            responseLength: reservations.length,
            loading: dataLoading,
            hasRowsSelection: true,
            clickEvent: (page, pageSize = paginationPageSize) => {
              onGetReservations({ page, pageSize, ...activeFilters });
            }
          }}
          onSelectionChange={rows => setHasSelectedReservations(!!rows.length)}
        /> }
      </DialogContent>
      <DialogActions>
        <Button onClick={ onAddReservationsModalClose } color="primary">{ t('global.modal.btnBack') }</Button>
        <Button onClick={ () => onAddReservationsModalSubmit(false) } color="primary">{ t('global.modal.btnAdd') }</Button>
      </DialogActions>

      <CustomDialog open={ maxCapacityModalOpen } onClose={ onMaxCapacityModalClose } fullWidth={ true } maxWidth="sm">
        <DialogContent>
          <DialogContentText>{ t('trips.tableReservations.maxCapacityWarning', {
            capacity: tripSelected.route.capacity,
            tableLength: tripsTableData.length,
            selectedLength: reservationsTableData.filter( reservation => reservation.tableData?.checked ).length
          }) }</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ onMaxCapacityModalClose } color="primary">{ t('global.modal.btnCancel') }</Button>
          <Button onClick={ () => onAddReservationsModalSubmit(true) } color="primary">{ t('global.modal.btnAdd') }</Button>
        </DialogActions>
      </CustomDialog>
    </React.Fragment>
  );
}

AddReservations.propTypes = {
  onAddReservationsModalClose: PropTypes.func.isRequired,
  tripsTableData: PropTypes.array.isRequired,
  setTripsTableData: PropTypes.func.isRequired
};

export default AddReservations;
