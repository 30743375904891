import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';

import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';

import { handleEndpointErrors, removeExtraSpaces, PASSWORD_REGEX, SNACKBAR_TIME } from '../../shared/utilities';

import CustomFormikForm from '../CustomFormikForm';
import FormikAutocomplete from '../FormikAutocomplete';
import FormikCheckbox from '../FormikCheckbox';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  userActive: {
    right: theme.spacing(3),
  },
  userTourist: {
    right: '130px',
  },
  username: {
    '& input': {
      textTransform: 'lowercase',
    },
  },
  passwordConditions: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
}));

const AddUser = (props) => {
  const { onGetUsers, onAddUserModalClose } = props;
  const classes = useStyles();

  const store = useStore();

  const roles = useStoreState(state => state.global.roles);
  const clients = useStoreState(state => state.global.clients);
  const clientSelected = useStoreState(state => state.global.clientSelected);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const organizationTypes = useStoreState(state => state.global.organizationTypes);
  const isTourismClient = localStorage.getItem('organizationType') && localStorage.getItem('organizationType') === organizationTypes[2];
  const timestamp = new Date().getTime();
  const [isTourist, setIsTourist] = React.useState(isTourismClient);

  const functionalAreas = useStoreState(state => state.users.functionalAreas);
  const getFunctionalAreas = useStoreActions(actions => actions.users.getFunctionalAreas);
  const addUser = useStoreActions(actions => actions.users.addUser);

  const formRef = React.useRef();

  const t = useTranslation();

  React.useEffect(() => {
    getFunctionalAreas().then(() => {
      const usersState = store.getState().users;
      if (!usersState.loading && !usersState.error) {
        // console.log(usersState);
      } else {
        handleEndpointErrors(usersState, props, setSnackbar, t);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <DialogTitle>{ t('users.table.actionAdd') }</DialogTitle>
      <Formik
        innerRef={ formRef }
        initialValues={{
          active: true,
          tourist: isTourist,
          name: isTourist ? 'John Doe' : '',
          expirationDate: new Date(),
          identifier: isTourist ? `${ timestamp }` : '',
          username: isTourist ? `${ timestamp }` : '',
          email: isTourist ? `${ timestamp }@mail.com` : '',
          password: isTourist ? 'P@ssword1' : '',
          phoneNumber: isTourist ? '+5062020-2022' : '',
          role: isTourist ? 'passenger' : '',
          functionalArea: '',
          client: ''
        }}
        validationSchema={ Yup.object({
          name: Yup.string()
            .max(50, t('global.errors.max', { field: t('users.name'), length: 50 }))
            .required(t('global.errors.required', { field: t('users.name') })),
          identifier: Yup.string()
            .nullable()
            .max(35, t('global.errors.max', { field: t('users.identifier'), length: 35 })),
            // .required(t('global.errors.required', { field: t('users.identifier') })),
          username: Yup.string()
            .max(15, t('global.errors.max', { field: t('users.username'), length: 15 }))
            .required(t('global.errors.required', { field: t('users.username') })),
          email: Yup.string()
            .max(40, t('global.errors.max', { field: t('users.email'), length: 40 }))
            .email(t('global.errors.email'))
            .required(t('global.errors.required', { field: t('users.email') })),
          password: Yup.string()
            .matches(PASSWORD_REGEX, t('global.errors.password', { field: t('users.table.password') }))
            .required(t('global.errors.required', { field: t('users.table.password') })),
          phoneNumber: Yup.string()
            .nullable()
            .max(30, t('global.errors.max', { field: t('users.phoneNumber'), length: 30 })),
            // .required(t('global.errors.required', { field: t('users.phoneNumber') })),
          role: Yup.string()
            .required(t('global.errors.required', { field: t('users.role') })),
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            active: values.active,
            name: removeExtraSpaces(values.name),
            ...(!!values.identifier && { identifier: values.identifier }),
            username: values.username.toLowerCase(),
            email: values.email,
            role: values.role,
            ...(values.client && values.client.id !== 'all' && { clientId: +values.client.id }),
            ...(values.functionalArea && values.functionalArea.id && { functionalAreaId: +values.functionalArea.id }),
            phoneNumber: values.phoneNumber,
            password: values.password,
            organizationId: localStorage.getItem('organizationId'),
            expirationDate: isTourist ? values.expirationDate.toLocaleDateString('en') : null
          };
          addUser(data).then(() => {
            const usersState = store.getState().users;
            setSubmitting(false);
            if (!usersState.loading && !usersState.error) {
              onGetUsers();
              setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
              onAddUserModalClose();
            } else {
              handleEndpointErrors(usersState, props, setSnackbar, t, t('users.errorEntity'));
            }
          });
        }}
      >
        {({ submitForm, isSubmitting, values, setFieldValue }) => (
          <CustomFormikForm>
            <DialogContent>
              <Field
                component={ FormikCheckbox }
                className={clsx( 'formik-checkbox', classes.userActive )}
                name="active"
                label={ t('users.active') }
                disabled={ isSubmitting }
              />
              { isTourismClient && <Field
                component={ FormikCheckbox }
                className={clsx( 'formik-checkbox', classes.userTourist )}
                name="tourist"
                label={ t('users.tourist') }
                disabled={ isSubmitting }
                onChange={() => {
                  setIsTourist(!isTourist);
                  setTimeout(() => formRef.current.resetForm());
                }}
              /> }
              <Grid container spacing={ 3 }>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="name"
                    type="text"
                    label={ t('users.name') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                { isTourist && <Grid item xs={ 6 } md={ 4 }>
                  <MuiPickersUtilsProvider utils={ DateFnsUtils } locale={ localStorage.getItem('language') === 'en' ? enLocale : esLocale }>
                    <Field
                      component={ DatePicker }
                      name="expirationDate"
                      label={ t('users.expirationDate') }
                      inputVariant="outlined"
                      fullWidth
                      autoOk
                      format="MM/dd/yyyy"
                      minDate={ new Date() }
                    />
                  </MuiPickersUtilsProvider>
                </Grid> }
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="identifier"
                    type="text"
                    label={ t('users.identifier') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    className={ classes.username }
                    name="username"
                    type="text"
                    label={ t('users.username') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="email"
                    type="text"
                    label={ t('users.email') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ FormikAutocomplete }
                    name="role"
                    disableClearable
                    options={ roles }
                    getOptionLabel={ option => option ? t(`global.roles.${ option }`) : '' }
                    textFieldProps={{ label: t('users.role'), variant: 'outlined', readOnly: true, fullWidth: true }}
                    onChange={() => {
                      setFieldValue(
                        'client',
                        clientSelected !== 'all' ? clients.find(client => client.id === +clientSelected) : ''
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ FormikAutocomplete }
                    name='client'
                    disableClearable
                    options={ clients.filter(client => ![values.client?.id].includes(client.id)) }
                    getOptionLabel={ option => option ? option.name : '' }
                    textFieldProps={{ label: t('users.client'), variant: 'outlined', readOnly: true, fullWidth: true }}
                    disabled={ values.role !== 'passenger' }
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ FormikAutocomplete }
                    name="functionalArea"
                    disableClearable
                    options={ functionalAreas }
                    getOptionLabel={ option => option ? option.name : '' }
                    textFieldProps={{ label: t('users.functionalArea'), variant: 'outlined', readOnly: true, fullWidth: true }}
                    disabled={ !functionalAreas.length }
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="phoneNumber"
                    type="text"
                    label={ t('users.phoneNumber') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="password"
                    type={ isTourist ? 'text' : 'password' }
                    label={ t('users.table.password') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 8 }>
                  <Typography variant="overline">{ t('user.changePassword.passwordRequirements') }</Typography>
                  <ul className={ classes.passwordConditions }>
                    { JSON.parse( t('global.errors.passwordConditions') ).map(condition => <li key={ condition }>- { condition }</li>) }
                  </ul>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onAddUserModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnAdd') }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

AddUser.propTypes = {
  onGetUsers: PropTypes.func.isRequired,
  onAddUserModalClose: PropTypes.func.isRequired,
};

export default AddUser;
