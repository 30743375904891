import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useStoreActions, useStore } from 'easy-peasy';

import { makeStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import * as Sentry from '@sentry/browser';

import { CSVReader } from 'react-papaparse';

import exampleCsvFile from '../../assets/add_users_example.csv';

import { handleEndpointErrors, removeExtraSpaces, SNACKBAR_TIME } from '../../shared/utilities';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialogContent-root': {
      position: 'relative',
      '& .MuiDivider-root': {
        margin: theme.spacing(3, 0),
        width: '100%',
      },
    },
    '& .reader > div': {
      borderColor: `${ theme.palette.divider } !important`,
      '& > div': {
        background: `${ theme.palette.secondary.overlay } !important`,
        height: '130px !important',
        width: '250px !important',
        '& > div:nth-child(2) span': {
          background: 'none !important',
          wordBreak: 'break-all',
        },
      },
    },
    '& svg': {
      color: 'green',
      '& path:nth-child(1)': {
        fill: theme.palette.error.main,
      },
    },
  },
  exampleCsvFile: {
    ...theme.typography.button,
    color: theme.palette.info.main,
    position: 'absolute',
    right: theme.spacing(3),
    textDecoration: 'none',
    top: theme.spacing(-2),
  },
  users: {
    marginTop: theme.spacing(2),
    '& > .MuiTypography-root': {
      paddingBottom: theme.spacing(1),
    },
    '& > div': {
      maxHeight: '210px',
      overflow: 'auto',
    },
    '& .csv-user': {
      borderRadius: theme.spacing(2.5),
      display: 'inline-block',
      margin: theme.spacing(.75, 1.5, .75, 0),
      padding: theme.spacing(1, 1.5),
    },
  },
  validUsers: {
    '& .csv-user': {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.common.white,
    },
  },
  invalidUsers: {
    '& > .MuiTypography-root': {
      color: theme.palette.error.main,
    },
    '& .csv-user': {
      backgroundColor: theme.palette.divider,
      color: theme.palette.text.primary,
      whiteSpace: 'pre',
    },
  },
}));

const AddUsersFromCSV = (props) => {
  const { onGetUsers, onAddUsersFromCSVModalClose, theme } = props;
  const classes = useStyles();
  const [validUsers, setValidUsers] = React.useState([]);
  const [invalidUsers, setInvalidUsers] = React.useState([]);

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const addUser = useStoreActions(actions => actions.users.addUser);

  const onDrop = (data) => {
    setValidUsers([]);
    setInvalidUsers([]);
    const usersData = data.map(user => user.data).filter(user => {
      const testUserValidation = !!user.name && !!user.identifier && !!user.username && !!user.email && !!user.role && !!user.phoneNumber && !!user.password;
      if (!testUserValidation) {
        setInvalidUsers(users => [...users, user]);
      };
      return testUserValidation;
    }).map(user => ({
      name: `${ removeExtraSpaces(user.name) }`,
      identifier: `${ user.identifier }`,
      username: `${ user.username }`,
      email: `${ user.email }`,
      role: `${ user.role }`,
      phoneNumber: `${ user.phoneNumber }`,
      password: `${ user.password }`,
      organizationId: localStorage.getItem('organizationId'),
      ...(user.clientId && { clientId: +user.clientId }),
      ...(user.bloodType && { bloodType: user.bloodType }),
      ...(user.active && { active: user.active }),
    }));
    setTimeout(() => setValidUsers(usersData), 1500);
  };

  const onError = (err, file, inputElem, reason) => {
    Sentry.captureException(err);
  };

  const onRemoveFile = () => {
    setValidUsers([]);
    setInvalidUsers([]);
  };

  const onAdd = () => {
    // eslint-disable-next-line array-callback-return
    validUsers.map((user, index) => {
      addUser(user).then(() => {
        const usersState = store.getState().users;
        if (!usersState.error) {
          setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
        } else {
          handleEndpointErrors(usersState, props, setSnackbar, t);
        }

        if (index === validUsers.length - 1) {
          onAddUsersFromCSVModalClose();
          onGetUsers();
        }
      });
    });
  };

  const t = useTranslation();

  return (
    <div className={ classes.root }>
      <DialogTitle>{ t('users.modal.addUsersFromCSV.title') }</DialogTitle>
      <DialogContent>
        { !validUsers.length && !invalidUsers.length && (
          <a className={ classes.exampleCsvFile } href={ exampleCsvFile } download>{ t('users.modal.addUsersFromCSV.exampleFile') }</a>
        ) }
        <div className="reader">
          <CSVReader
            onDrop={ onDrop }
            onError={ onError }
            addRemoveButton
            onRemoveFile={ onRemoveFile }
            progressBarColor={ theme.palette.primary.main }
            config={{
              header: true,
              dynamicTyping: true,
            }}
          >
            <span>{ t('users.modal.addUsersFromCSV.upload') }</span>
          </CSVReader>
        </div>
        { validUsers.length ? (
          <div className={clsx( classes.users, classes.validUsers )}>
            <Typography variant="body2">{ t('users.modal.addUsersFromCSV.valid', { validUsers: validUsers.length }) }</Typography>
            <div>
              { validUsers.map((validUser, index) => (
                <Typography className="csv-user" key={ index } variant="body2">{ validUser.name }</Typography>
              )) }
            </div>
          </div>
        ) : null }
        { invalidUsers.length ? (
          <React.Fragment>
            <Divider />
            <div className={clsx( classes.users, classes.invalidUsers )}>
              <Typography variant="body2">{ t('users.modal.addUsersFromCSV.invalid', { invalidUsers: invalidUsers.length }) }</Typography>
              <div>
                { invalidUsers.map((invalidUser, index) => (
                  <Typography className="csv-user" key={ index } variant="body2">{ JSON.stringify(invalidUser) }</Typography>
                )) }
              </div>
            </div>
          </React.Fragment>
        ) : null }
      </DialogContent>
      <DialogActions>
        <Button onClick={ onAddUsersFromCSVModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
        <Button onClick={ onAdd } disabled={ !validUsers.length } color="primary">{ t('global.modal.btnAdd') }</Button>
      </DialogActions>
    </div>
  );
}

AddUsersFromCSV.propTypes = {
  onGetUsers: PropTypes.func.isRequired,
  onAddUsersFromCSVModalClose: PropTypes.func.isRequired,
};

export default withTheme(AddUsersFromCSV);
