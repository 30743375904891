import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
// import Link from '@material-ui/core/Link';

import { handleEndpointErrors, TIME_REGEX, toHoursAndMinutes, SNACKBAR_TIME } from '../../shared/utilities';

import Table from '../Table';
import CustomDialog from '../CustomDialog';
import LinkPlace from './LinkPlace';
import AddPlace from './AddPlace';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  table: {
    '& .MuiTableCell-head:nth-child(2)': {
      minWidth: '275px',
    },
    '& .MuiTableCell-head:nth-child(3)': {
      minWidth: '170px',
    },
  },
}));

const Places = (props) => {
  const { route } = props;
  const classes = useStyles();
  const [placesTableData, setPlacesTableData] = React.useState([]);
  const [linkPlaceModalOpen, setLinkPlaceModalOpen] = React.useState(false);
  const [addPlaceModalOpen, setAddPlaceModalOpen] = React.useState(false);

  const store = useStore();

  const permissions = useStoreState(state => state.global.permissions);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const routePlaces = useStoreState(state => state.routes.routePlaces);
  const getRoutePlaces = useStoreActions(actions => actions.routes.getRoutePlaces);
  const deleteRoutePlace = useStoreActions(actions => actions.routes.deleteRoutePlace);
  const updateRoutePlace = useStoreActions(actions => actions.routes.updateRoutePlace);

  const getProvinces = useStoreActions(actions => actions.countries.getProvinces);

  const canEdit = permissions?.admin?.routes?.edit;

  const t = useTranslation();

  const placesTableColumns = [
    { title: t('route.places.id'), field: 'id', type: 'numeric', align: 'left', editable: 'never' },
    { title: t('route.places.description'), field: 'description', sorting: false, editable: 'never' },
    {
      title: `${ t('route.places.arrivalTime') } (24h)`, field: 'arrivalTime', defaultSort: 'asc',
      validate: rowData => rowData.arrivalTime.match(TIME_REGEX) ? true : { isValid: false, helperText: t('global.errors.time', { field: t('route.places.arrivalTime') }) },
    },
    { title: t('route.places.stop'), field: 'stop', type: 'boolean', sorting: false, editable: 'never' },
    { title: t('route.places.active'), field: 'active', type: 'boolean', sorting: false, editable: 'never' },
    { title: t('route.places.county'), field: 'county.name', sorting: false, editable: 'never' },
    { title: t('route.places.province'), field: 'province.name', sorting: false, editable: 'never' },
    // {
    //   title: t('route.places.latitude'), field: 'latitude',
    //   render: rowData => <Link target="_blank" rel="noopener" href={`https://www.google.com/maps?q=${ rowData.latitude },${ rowData.longitude },`}>{ rowData.latitude }</Link>,
    //   type: 'numeric', sorting: false, editable: 'never'
    // },
    // {
    //   title: t('route.places.longitude'), field: 'longitude',
    //   render: rowData => <Link target="_blank" rel="noopener" href={`https://www.google.com/maps?q=${ rowData.latitude },${ rowData.longitude },`}>{ rowData.longitude }</Link>,
    //   type: 'numeric', sorting: false, editable: 'never'
    // },
  ];

  const onGetRoutePlaces = (payload) => {
    getRoutePlaces(payload).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.routePlacesLoading && !routesState.routePlacesError) {
        // console.log(routesState);
      } else {
        handleEndpointErrors({ ...routesState, loading: routesState.routePlacesLoading, error: routesState.routePlacesError }, props, setSnackbar, t);
      }
    });
  }

  const onUpdateRoutePlace = (newData, oldData, resolve, reject) => {
    const oldArrival = oldData.arrivalTime;
    const newArrival = newData.arrivalTime;
    if (oldArrival === newArrival) {
      resolve();
    } else if (newArrival.match(TIME_REGEX)) {
      updateRoutePlace({ placeId: newData.routePlaceId, data: { arrivalTime: newArrival } }).then(() => {
        const routesState = store.getState().routes;
        if (!routesState.loading && !routesState.error) {
          // console.log('success', routesState);
          resolve();
          const data = [...placesTableData];
          const place = data.find(place => place.id === newData.id);
          place.arrivalTime = newArrival;
          setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
        } else {
          reject();
          handleEndpointErrors(routesState, props, setSnackbar, t);
        }
      });
    } else {
      reject();
    }
  };

  const onDeleteRoutePlace = (oldData, resolve, reject) => {
    deleteRoutePlace(oldData.routePlaceId).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.error) {
        // console.log('success', routesState);
        resolve();
        const data = [...placesTableData].filter(place => place.id !== oldData.id);
        setPlacesTableData(data);
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        reject();
        handleEndpointErrors(routesState, props, setSnackbar, t);
      }
    });
  };

  const onLinkPlaceModalClose = () => {
    setLinkPlaceModalOpen(false);
  };

  const onAddPlaceModalClose = () => {
    setAddPlaceModalOpen(false);
  };

  React.useEffect(() => {
    getProvinces().then(() => {
      const countriesState = store.getState().countries;
      if (!countriesState.loading && !countriesState.error) {
        // console.log(countriesState);
      } else {
        handleEndpointErrors(countriesState, props, setSnackbar, t);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (route.id) {
      onGetRoutePlaces(route.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  React.useEffect(() => {
    setPlacesTableData(routePlaces.map(place => ({
      ...place,
      arrivalTime: toHoursAndMinutes(place.arrivalTime)
    })));
  }, [routePlaces]);

  return (
    <React.Fragment>
      <Table
        className={ classes.table }
        title=""
        columns={ placesTableColumns }
        data={ placesTableData }
        paging={ false }
        updateEvent={ canEdit ? onUpdateRoutePlace : null }
        deleteEvent={ canEdit ? onDeleteRoutePlace : null }
        addActions={canEdit ? [
          {
            icon: 'link',
            tooltip: t('route.places.link.action'),
            isFreeAction: true,
            onClick: (_, rowData) => {
              setLinkPlaceModalOpen(true);
            }
          },
          {
            icon: 'add',
            iconProps: {
              color: 'primary'
            },
            tooltip: t('route.places.add.action'),
            isFreeAction: true,
            onClick: (_, rowData) => {
              setAddPlaceModalOpen(true);
            }
          }
        ] : []}
      />

      <CustomDialog open={ linkPlaceModalOpen } onClose={ onLinkPlaceModalClose } fullWidth={ true } maxWidth="md">
        <LinkPlace
          routeId={ route.id }
          placeIds={ placesTableData.map(place => place.id) }
          onGetRoutePlaces={ onGetRoutePlaces }
          onLinkPlaceModalClose={ onLinkPlaceModalClose }
        />
      </CustomDialog>

      <CustomDialog open={ addPlaceModalOpen } onClose={ onAddPlaceModalClose } fullWidth={ true } maxWidth="md">
        <AddPlace
          routeId={ route.id }
          onGetRoutePlaces={ onGetRoutePlaces }
          onAddPlaceModalClose={ onAddPlaceModalClose }
        />
      </CustomDialog>
    </React.Fragment>
  );
}

Places.propTypes = {
  route: PropTypes.object.isRequired
};

export default Places;
