import { action, thunk } from 'easy-peasy';

import { getTrips, getHeatMapTrips, getTrip, getReservations, getTripsReport } from '../../services/trips/get';
import { addTrip, updateTripStatus, generateTrips, generateReservations, assignReservations } from '../../services/trips/post';
import { updateTrip, deleteTripPassenger, updateTripPassenger, addMultipleReservations } from '../../services/trips/put';
import { deleteTripStatus } from '../../services/trips/delete';

const filter_properties = ['date', 'parkId', 'status', 'routeTypes', 'routeDirections', 'tripExtra'];
export const DEFAULT_TRIP_FILTERS = {
  parkId: [],
  date: [],
  status: [],
  routeTypes: [],
  routeDirections: [],
  tripExtra: []
};

const tripsModel = {
  trips: [],
  heatMapTrips: [],
  currentTrip: {},
  reservations: [],
  reservationsPage: 1,
  reservationsPageSize: 25,
  tripsReport: [],
  tripsReportDates: {},
  tripsReportPage: 1,
  tripsReportPageSize: localStorage.getItem('tripsReportPageSize') ? localStorage.getItem('tripsReportPageSize') : 50,
  tripsReportTextSearch: '',
  loading: false,
  error: '',
  heatMapLoading: false,
  heatMapLoadingError: '',
  reservationsLoading: false,
  reservationsError: '',
  search: '',
  filters: localStorage.getItem('filters') && filter_properties.every(prop => prop in JSON.parse(localStorage.getItem('filters'))) ?
    JSON.parse(localStorage.getItem('filters')) : DEFAULT_TRIP_FILTERS,
  filtersArray: [],
  heatMapIconsSelected: localStorage.getItem('heatMapIconsSelected') || 'hidden',
  page: 1,
  pageSize: localStorage.getItem('tripsPageSize') ? localStorage.getItem('tripsPageSize') : 15,
  pageSizeOptions: [5, 15, 25, 50],
  hasMoreTrips: false,
  endpointCalls: 0,
  currentTripLoading: false,
  currentTripError: '',
  getTrips: thunk(async (actions, payload = {}, { getState, getStoreState }) => {
    actions.setLoading(payload.loading);
    actions.setError('');
    return await getTrips(actions, payload.isLoadMore, getState(), getStoreState());
  }),
  getHeatMapTrips: thunk(async (actions, loading,  {getState, getStoreState }) => {
    actions.setHeatMapLoading(loading);
    actions.setHeatMapError('');
    return await getHeatMapTrips(actions, getState(), getStoreState());
  }),
  getTrip: thunk(async (actions, payload = {}) => {
    actions.setCurrentTripLoading(payload.loading);
    actions.setCurrentTripError('');
    return await getTrip(actions, payload);
  }),
  getReservations: thunk(async (actions, payload = {}, { getState }) => {
    actions.setReservationsLoading(payload.loading);
    actions.setReservationsError('');
    return await getReservations(actions, payload, getState());
  }),
  getTripsReport: thunk(async (actions, payload = {}, { getState, getStoreState }) => {
    actions.setLoading(payload.loading);
    actions.setError('');
    return await getTripsReport(actions, payload, getState(), getStoreState());
  }),
  addTrip: thunk(async (actions, payload) => {
    actions.setError('');
    return await addTrip(actions, payload);
  }),
  generateTrips: thunk(async (actions, payload) => {
    actions.setError('');
    return await generateTrips(actions, payload);
  }),
  generateReservations: thunk(async (actions, payload) => {
    actions.setError('');
    return await generateReservations(actions, payload);
  }),
  assignReservations: thunk(async (actions, payload) => {
    actions.setError('');
    return await assignReservations(actions, payload);
  }),
  updateTrip: thunk(async (actions, payload) => {
    actions.setError('');
    await updateTrip(actions, payload);
  }),
  updateTripStatus: thunk(async (actions, payload) => {
    actions.setError('');
    await updateTripStatus(actions, payload);
  }),
  updateTripPassenger: thunk(async (actions, payload) => {
    actions.setError('');
    await updateTripPassenger(actions, payload);
  }),
  deleteTripPassenger: thunk(async (actions, payload) => {
    actions.setError('');
    await deleteTripPassenger(actions, payload);
  }),
  deleteTripStatus: thunk(async (actions, payload) => {
    actions.setError('');
    await deleteTripStatus(actions, payload);
  }),
  addMultipleReservations: thunk(async (actions, payload) => {
    actions.setError('');
    await addMultipleReservations(actions, payload);
  }),
  storeGetTrips: action((state, payload) => {
    const updateTripsPassengers = trips => {
      return trips.map( trip => {
        trip.passengers = trip.passengers !== null ? trip.passengers : [];
        return trip;
      } );
    };

    state.trips = payload.isLoadMore ? [...state.trips, ...updateTripsPassengers(payload.data)] : updateTripsPassengers(payload.data);
    state.hasMoreTrips = +state.pageSize === +payload.data.length;
    state.page = payload.isLoadMore ? state.page + 1 : 1;
    state.endpointCalls = state.endpointCalls + 1;

    const tripsIds = payload.data.map(trip => trip.id);
    const currentTrip = state.currentTrip;
    if( tripsIds.includes(currentTrip.id) ) {
      currentTrip.status = payload.data.find(trip => trip.id === currentTrip.id).status;
    }
  }),
  storeGetHeatMapTrips: action((state, payload) => {
    state.heatMapTrips = payload;
    const tripsIds = payload.map(trip => trip.id);
    const currentTrip = state.currentTrip;
    if( tripsIds.includes(currentTrip.id) ) {
      currentTrip.status = payload.find(trip => trip.id === currentTrip.id).status;
    }
  }),
  storeGetReservations: action((state, payload) => {
    state.reservations = payload.data;
    state.reservationsPage = payload.page ? payload.page : 1;
    state.reservationsPageSize = payload.pageSize ? payload.pageSize : 25;
  }),
  storeGetTripsReport: action((state, payload) => {
    state.tripsReport = payload.data;
    state.tripsReportPage = payload.page ? payload.page : 1;
    state.tripsReportTextSearch = payload.textSearch ? payload.textSearch : '';
  }),
  setTripsReportDates: action((state, payload) => {
    state.tripsReportDates = payload;
  }),
  storeUpdateTripStatus: action((state, payload) => {
    const trip = state.trips.find(trip => trip.id === payload.id);
    const heatMapTrip = state.heatMapTrips.find(heatMapTrip => heatMapTrip.id === payload.id);
    if (trip) {
      trip.status = payload.status;
      trip.notes = payload.note;
    }
    if (heatMapTrip) {
      heatMapTrip.status = payload.status;
      heatMapTrip.notes = payload.note;
    }
    if (payload.status === 'confirmed') {
      state.currentTrip.wasConfirmed = true;
    }
    state.currentTrip.status = payload.status;
    state.currentTrip.notes = payload.note;
  }),
  storeUpdateTripPassenger: action((state, payload) => {
    const passenger = state.trips.find(trip => trip.id === payload.tripId).passengers.find(passenger => passenger.reservation.id === payload.reservationId);
    passenger.status = payload.status;
    passenger.reservation.displayOrder = payload.displayOrder;
  }),
  storeDeleteTripPassenger: action((state, payload) => {
    state.trips.find(trip => trip.id === payload.tripId).passengers = state.trips.find(trip => trip.id === payload.tripId).passengers.filter(passenger => passenger.reservation.id !== payload.reservationId);
  }),
  setCurrentTrip: action((state, payload) => {
    state.currentTrip = {
      ...payload,
      ...(payload.passengers?.length && { passengers: [
        ...payload.passengers.sort((a, b) => a.reservation.displayOrder - b.reservation.displayOrder),
      ] }),
    };
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setHeatMapLoading: action((state, payload) => {
    state.heatMapLoading = payload;
  }),
  setHeatMapError: action((state, payload) => {
    state.heatMapError = payload;
  }),
  setReservationsLoading: action((state, payload) => {
    state.reservationsLoading = payload;
  }),
  setReservationsError: action((state, payload) => {
    state.reservationsError = payload;
  }),
  setFilters: action((state, payload) => {
    state.filters = payload;
    localStorage.setItem('filters', JSON.stringify(payload));

    const filtersArray = [];
    // eslint-disable-next-line array-callback-return
    Object.keys(state.filters).map(filterName => {
      state.filters[filterName].map(item => filtersArray.push({ name: item, type: filterName }));
    });
    state.filtersArray = filtersArray;
  }),
  setHeatMapIconsSelected: action((state, payload) => {
    state.heatMapIconsSelected = payload;
    localStorage.setItem('heatMapIconsSelected', payload);
  }),
  setSearch: action((state, payload) => {
    state.search = payload;
  }),
  setPageSize: action((state, payload) => {
    state.pageSize = payload;
    localStorage.setItem('tripsPageSize', payload);
  }),
  setCurrentTripLoading: action((state, payload) => {
    state.currentTripLoading = payload;
  }),
  setCurrentTripError: action((state, payload) => {
    state.currentTripError = payload;
  }),
}

export default tripsModel;
