import { action, thunk } from 'easy-peasy';

import compareVersions from 'compare-versions';

import getVersion from '../../services/version/get';
import { getUserMetadata } from '../../services/global/get';

import { DEFAULT_SNACKBAR, updatePermissions } from '../../shared/utilities';

const globalModel = {
  version: {
    loading: true,
    error: '',
    update: false,
    version: '1.2.1',
  },
  theme: localStorage.getItem('theme') === 'dark' ? 'dark' : 'light',
  title: '',
  snackbar: DEFAULT_SNACKBAR,
  organizationTypes: [],
  bloodTypes: ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'],
  roles: ['passenger', 'driver', 'admin'],
  routeDirections: ['in', 'out'],
  routeTypes: [],
  dashboardRefreshTime: localStorage.getItem('dashboardRefreshTime') ? localStorage.getItem('dashboardRefreshTime') : 3,
  clients: [],
  clientSelected: localStorage.getItem('clientSelected') || 'all',
  clientsDisabled: false,
  permissions: updatePermissions([]),
  setTheme: action((state, payload) => {
    const theme = payload === 'light' ? 'dark' : 'light';
    state.theme = theme ;
    localStorage.setItem('theme', theme);
  }),
  getVersion: thunk(async (actions) => {
    return await getVersion(actions);
  }),
  getUserMetadata: thunk(async (actions, payload) => {
    return await getUserMetadata(actions, payload);
  }),
  storeGetVersion: action((state, payload) => {
    if ( compareVersions(payload, state.version.version) === 1 ) {
      state.version.update = true;
    } else {
      state.version.update = false;
    }
  }),
  storeGetUserMetadata: action((state, payload) => {
    localStorage.setItem('id', payload.id);
    localStorage.setItem('name', payload.name);
    localStorage.setItem('username', payload.username);
    localStorage.setItem('email', payload.email);
    localStorage.setItem('routeTypes', JSON.stringify(payload.routeTypes));
    localStorage.setItem('organizationId', payload.organization.id);
    localStorage.setItem('organizationType', payload.organization.type);
    localStorage.setItem('countryId', payload.organization.countryId);
    localStorage.setItem('schema', payload.organization.schema);
    localStorage.setItem('enums', JSON.stringify(payload.organization.settings.enums));
    state.permissions = updatePermissions(payload.actions);
  }),
  setVersionLoading: action((state, payload) => {
    state.version.loading = payload;
  }),
  setVersionError: action((state, payload) => {
    state.version.error = payload;
  }),
  storeTitle: action((state, payload) => {
    state.title = payload;
  }),
  setSnackbar: action((state, payload) => {
    state.snackbar = payload;
  }),
  setOrganizationTypes: action((state) => {
    state.organizationTypes = localStorage.getItem('enums') ? JSON.parse(localStorage.getItem('enums')).organizationTypes : [];
  }),
  setRouteTypes: action((state) => {
    state.routeTypes = localStorage.getItem('routeTypes') ? JSON.parse(localStorage.getItem('routeTypes')) : [];
  }),
  setDashboardRefreshTime: action((state, payload) => {
    state.dashboardRefreshTime = payload;
    localStorage.setItem('dashboardRefreshTime', payload);
  }),
  setClients: action((state, payload) => {
    state.clients = payload;
  }),
  setClientSelected: action((state, payload) => {
    state.clientSelected = payload;
    localStorage.setItem('clientSelected', payload);
  }),
  setClientsDisabled: action((state, payload) => {
    state.clientsDisabled = payload;
  }),
}

export default globalModel;
